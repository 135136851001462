.nav_show {
  transform: translateY(0);
  opacity: 1;
}

.nav_hide {
  transform: translateY(-100vh);
  opacity: 0;
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  transition: 0.5s ease;
}
