.delete_container{
    padding-block: 75px;
    background-color: #f1f3f7;
}

.delete_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 39px;
}

.delete_card{
    background-color: white;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 526px;
    /* height: 549px; */
    box-shadow: 0px 2px 4px #386B980D;
    border-radius: 4px;
    opacity: 1;
    padding-inline: 54px ;
    padding-block: 47px;
}

.my_header {
    font-size: 18px;
    font-weight: bold;
    flex: 1;
    display: flex;
    align-items: center;
    gap: 14px;
  }

  .my_title{
    font: normal normal 600 18px/36px Open Sans;
    letter-spacing: 0px;
    color: #364753;
  }

  .modal_paragraph{
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    letter-spacing: 0px;
    color: #364753;
    opacity: 1;
    margin-bottom: 49px;
    width: 100%;
    max-width: 401px;
  }

  .second_paragraph{
    margin-bottom: 19px;
    width: 100%;
    max-width: 343px;
  }

  .modal_list{
    display: flex;
    flex-direction: column;
    gap: 17px;
    list-style-position: inside;
  }
  .modal_list li{
      font-size: 16px;
      text-align: left;
        letter-spacing: 0px;
        color: #3A3A3A;
        opacity: 1;
  }

  .delete_card_links{
    color: #2087e2;
    font-weight: 600;
  }

@media screen and (max-width: 576px) {
    .delete_container{
        padding-block: 70px;
    }
    .delete_card{
        width: 95% !important;
        height: auto !important;
        padding-inline: 20px !important;
    }
}