.jumbotron {
  background-color: white;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.heroText {
  padding: 10px;
}

.smallTitle {
  height: 10px;
  color: #3a3a3a;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 10px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.bigTitle {
  color: #3a3a3a;
  font-family: 'Open Sans';
  font-size: 34px;
  font-weight: bold;
  line-height: 46px;
}

.paddingLg {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.banner {
  height: 90vh;
  /* background-image: url(/img/payment-banner_.jpg); */
  background-image: url(/img/Banner_Icon2.jpg);
  background-size: cover;
  background-position-x: center;
  position: relative;
}

.banner__text__h1 {
  max-width: 100%;
}

.banner__content {
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.banner__text p {
  text-transform: uppercase;
}
.banner__text h1 {
  width: 23ch;
  margin-bottom: 32px;
  font-weight: 700;
  font-size: clamp(23px, 4vw, 34px);
}

.banner__actions {
  display: flex;
  gap: 20px;
}
.banner__actions img {
  height: min-content;
}

@media (max-width: 767.98px) {
  .banner {
    /* background-image: url(/img/payment-banner-mobile.png); */
    background-image: url(/img/Banner_Icon_Mobile2.png);
    height: 83vh;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 50px;
  }

  .banner__content {
    position: absolute;
    width: 100vw;
    height: auto;
    display: block;
    bottom: 0;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-block-start: 30px;
    padding-block-end: 43px;
  }

  .banner__actions {
    display: flex;
    gap: 16px;
    justify-content: center;
  }

  .banner__actions img {
    height: 48px;
  }

  .banner__text > * {
    text-align: center;
    margin-inline: auto;
  }
}
