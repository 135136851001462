.deleteDialog {
  background-color: rgba(0, 0, 0, 0.409);
  position: fixed;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
}
.deleteDialog__open {
  opacity: 1;
  bottom: 0vh;
}
.deleteDialog__close {
  bottom: 100vh;
  opacity: 0;
}

.deleteDialog__content {
  background-color: white;
  width: max(50%, 350px);
  max-width: 400px;
  margin-inline: auto;
  margin-top: 20vh;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding: 30px 40px 35px;
}

.deleteDialog__heading {
  margin-bottom: 20px;
  color: rgb(93, 108, 122);
  font-weight: 500;
  line-height: 19px;
}

.deleteDialog__text {
  margin-bottom: 40px;
  color: rgb(93, 108, 122);
  line-height: 19px;
}

.deleteDialog__buttonGroup {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.deleteDialog__buttonGroup > button {
  padding-inline: 40px;
}
