.nav_show {
  transform: translateY(0);
  opacity: 1;
}

.nav_hide {
  transform: translateY(-100vh);
  opacity: 0;
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  transition: 0.5s ease;
}

.nav_content {
  /* height: 70vh; */
  background: white;
  width: 100vw;
  position: absolute;
  /* top: 80px; */
  top: 60px;
  z-index: 1500;
  transition: 0.5s ease;
  padding: 50px;
}

.nav {
  position: fixed;
  top: 0;
  width: 100vw;
  /* height: 80px; */
  height: 60px;
  background: white;
  z-index: 1040;
}

.nav_shadow {
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
}

.nav_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  padding: 15px;
}

.icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_links {
  display: flex;
  flex-direction: column;
}
.nav_link {
  text-decoration: none;
  background-color: transparent;
  padding: 17px;
  color: #3a3a3a;
  font-size: 16px;
  font-weight: 600;
  padding-left: 0;
}

.button_group {
  display: flex;
  gap: 17px;
  /* justify-content: center; */
  align-items: center;
  margin-top: 50px;
}

.button {
  border: none;
  background: #007bff;
  color: white;
  padding: 7px;
  border-radius: 30px;
  padding-inline: 36px;
  font-size: 13px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
}

.button_inverse {
  background-color: white;
  color: #007bff;
}
