.generalDialog {
  background-color: rgba(0, 0, 0, 0.409);
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease;
  z-index: 1031;
}
.generalDialog__open {
  opacity: 1;
  width: 100vw;
  height: 100vh;
}
.generalDialog__close {
  opacity: 0;
  width: 0;
  height: 0;
  z-index: -2;
  overflow: hidden;
}
.generalDialog__close_icon {
  background: none;
  font-size: 10px;
  color: #000;
	text-align: center;
  position: absolute;
  top: 0;
  right: 0;
	width: 20px;
	height: 20px;
  border: solid 1px #777;
	border-radius: 20px;
  outline: none;
}
.generalDialog__content {
  background-color: white;
  width: min(350px, 85vw);
  margin-inline: auto;
  margin-top: 20vh;
  border-radius: .3rem;
  overflow: hidden;
  text-align: center;
  padding: 1rem 50px;
}

.generalDialog__heading {
  position: relative;
  margin-bottom: 20px;
}

.generalDialog__text {
  margin-bottom: 40px;
}

.generalDialog__buttonGroup {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.generalDialog__buttonGroup > button {
  padding-inline: 40px;
}
